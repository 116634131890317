
import { defineComponent, Ref, ref, PropType, computed } from "vue"
import { formatDatefromSeconds, StringToSeconds, formatDate } from "@/services/DurationFormatter"
import injectStrict from "@/utils/injectStrict"
import { LoggerKey, translationKey } from "@/symbols"
import { TrailerMomentInfo } from "@/models/TrailerMomentInfo"
import { RelatedResourceData } from "@/models/RelatedResourceData"
import { Translation } from "@/models/translations"
import getResourceSvg from "@/services/relatedResourceSvgProvider"
const WIDTH_PER_MOMENT = 175

let count = 0
export default defineComponent({
  props: {
    moments: {
      type: Object as PropType<Array<TrailerMomentInfo>>,
      required: true
    },
    resources: {
      type: Object as PropType<Array<RelatedResourceData> | null>,
      required: false,
      default: null
    },
    webinar_duration: { type: String, required: true },
    title: { type: String, required: false }
  },
  setup(props) {
    const ZERO_WIDTH_SPACES_REGEX = /([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g

    let title_txt = props.title
      ? props.title.replace(ZERO_WIDTH_SPACES_REGEX, "").replace("{{ moment_count }}", props.moments.length.toString())
      : ""
    let number_of_moments = ref(4.5)
    const logger = injectStrict(LoggerKey)
    const trackElm = ref<HTMLElement>()
    let base_moment = 0
    let momentElms = ref<Array<HTMLElement>>([])
    let isTransitioning = ref(false)
    let totDuration = StringToSeconds(props.webinar_duration)
    let dir_isRight = false
    props.moments.sort((a, b) => (a.start_time < b.start_time ? -1 : 1))
    let prev = 0
    let used_per = 0
    let momentDataArr: Ref<Array<MomentData>> = ref([])
    let isVisited = ref(false)
    let translate = ref(false)
    let current_moment = ref(0)
    props.moments.forEach((moment, i) => {
      let space = moment.start_time - prev
      let dur = moment.end_time - moment.start_time
      let space_per = (space * 100) / totDuration
      let dur_per = (dur * 100) / totDuration
      momentDataArr.value.push(
        new MomentData(
          i,
          Math.floor((used_per + space_per) * 100) / 100,
          Math.floor((used_per + space_per + dur_per) * 100) / 100,
          moment.poster_url,
          moment.title,
          moment.duration,
          false,
          "",
          moment.translations
        )
      )
      prev = moment.end_time
      used_per += space_per + dur_per
    })
    props.resources?.forEach((resource, i) => {
      momentDataArr.value.push(
        new MomentData(i + props.moments.length, 0, 0, "", resource.title, "", true, resource.icon, resource.translations)
      )
    })
    let activeMoments: Ref<Array<number>> = ref([])
    let css = computed(() => {
      let end =
        current_moment.value + Math.floor(number_of_moments.value) < momentDataArr.value.length
          ? current_moment.value + Math.floor(number_of_moments.value)
          : momentDataArr.value.length
      activeMoments.value = []
      for (var i = current_moment.value; i < end; i++) activeMoments.value.push(i)
      end = Math.floor(number_of_moments.value) - activeMoments.value.length
      for (var i = 0; i < end; i++) activeMoments.value.push(i)
      let cs = "linear-gradient(to right,"
      prev = 0
      momentDataArr.value.forEach(moment => {
        let isActive = activeMoments.value.includes(moment.id)
        cs += `var(--theme-color-2) ${prev}% ${moment.start_per}%,
      ${isActive ? "var(--theme-color)" : "var(--theme-color-5)"} ${moment.start_per}% ${moment.end_per}%,`
        prev = moment.end_per
      })
      cs += `var(--theme-color-2) ${prev}% 100%) !important`
      return { background: cs }
    })
    const { translateTrailerBox, translateLabels } = injectStrict(translationKey)
    return {
      title_txt,
      trackElm,
      activeMoments,
      css,
      base_moment,
      current_moment,
      dir_isRight,
      momentElms,
      logger,
      isTransitioning,
      translate,
      isVisited,
      momentDataArr,
      number_of_moments,
      formatDatefromSeconds,
      formatDate,
      getResourceSvg,
      translateTrailerBox,
      translateLabels
    }
  },
  mounted() {
    this.number_of_moments = (this.trackElm?.clientWidth ?? 0) / WIDTH_PER_MOMENT
    this.number_of_moments = this.number_of_moments < 1 ? 1 : this.number_of_moments
    if (Math.ceil(this.number_of_moments) < this.momentDataArr.length - 1) this.setUpCarousel()
  },
  methods: {
    setUpCarousel() {
      this.momentElms.forEach(moment => {
        moment.addEventListener("transitionend", this.transitionComplted)
      })
      this.right()
    },
    right(to_skip: number = 1) {
      if (this.isTransitioning) return
      this.dir_isRight = true
      this.isTransitioning = true
      this.current_moment += to_skip
      if (this.current_moment >= this.momentDataArr.length) this.current_moment -= this.momentDataArr.length
      this.momentDataArr.forEach(moment => {
        moment.transform -= WIDTH_PER_MOMENT * to_skip
      })
      this.checkRight(to_skip)
    },
    transitionComplted() {
      this.isTransitioning = false
      count++
      if (count == this.momentElms.length) {
        count = 0
        this.momentDataArr.forEach(m => (m.opacity = 1))
        this.right()
      }
    },
    checkRight(to_skip: number = 1) {
      this.momentDataArr.forEach(moment => {
        if (moment.transform < moment.id * WIDTH_PER_MOMENT * -1 - to_skip * WIDTH_PER_MOMENT) {
          moment.opacity = 0
          moment.transform += WIDTH_PER_MOMENT * this.momentDataArr.length
        }
      })
    },
    getResourceLabel(lbl: string): string {
      switch (lbl) {
        case "WEB":
          return this.translateLabels("Web") ?? ""
          break
        case "INFOGRAPHIC":
          return this.translateLabels("Infographic") ?? ""
          break
        case "EBOOK":
          return this.translateLabels("eBook") ?? ""
          break
        case "BLOGPOST":
          return this.translateLabels("Blog") ?? ""
          break
        case "WEBINAR":
          return this.translateLabels("Webinar") ?? ""
          break
        case "VIDEO":
          return this.translateLabels("Video") ?? ""
          break
        case "PAPER":
          return this.translateLabels("White Paper") ?? ""
          break
        case "SLIDEDECK":
          return this.translateLabels("Slides") ?? ""
          break
        case "ARTICLE":
          return this.translateLabels("Article") ?? ""
          break
        default:
          throw new Error(this.translateLabels("Icon not found exception ") ?? "")
          break
      }
    }
  }
})

class MomentData {
  id: number
  duration: string
  start_per: number = 0
  end_per: number = 0
  poster_url: string
  title: string = ""
  isActive = false
  transform: number = 0
  opacity: number = 1
  isResource: boolean = false
  resourceType: string = ""
  translations: Translation[] | null | undefined
  constructor(
    id: number,
    start_per: number,
    end_per: number,
    poster_url: string,
    title: string,
    duration: string,
    isResource: boolean,
    resourceType: string,
    translations: Translation[] | null | undefined
  ) {
    this.id = id
    this.start_per = start_per
    this.end_per = end_per
    this.poster_url = poster_url
    this.title = title
    this.duration = duration
    this.translations = translations
    ;(this.isResource = isResource), (this.resourceType = resourceType)
  }
}

export default function getResourceSvg(category: string): string {
  switch (category) {
    case "WEB":
      return WEB_SVG
      break
    case "INFOGRAPHIC":
      return INFOGRAPHIC_SVG
      break
    case "EBOOK":
      return EBOOK_SVG
      break
    case "BLOGPOST":
      return BLOGPOST_SVG
      break
    case "WEBINAR":
      return WEBINAR_SVG
      break
    case "VIDEO":
      return VIDEO_SVG
      break
    case "PAPER":
      return PAPER_SVG
      break
    case "SLIDEDECK":
      return SLIDEDECK_SVG
      break
    case "ARTICLE":
      return ARTICLE_SVG
      break
    default:
      throw new Error("Icon not found exception")
      break
  }
}
const WEB_SVG = `<svg  "xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 288.58 288.58">
   <path class="icon-color" d="M144.29,288.58C64.73,288.58,0,223.85,0,144.29S64.73,0,144.29,0s144.29,64.73,144.29,144.29-64.73,144.29-144.29,144.29Zm0-278.9C70.07,9.68,9.68,70.07,9.68,144.29s60.38,134.61,134.61,134.61,134.61-60.38,134.61-134.61S218.51,9.68,144.29,9.68Z" />
   <path class="icon-color" d="M141.6,286.82c-18.5-22.39-49.77-70-51.35-137.21C88.49,74.57,125.58,21.3,141.61,1.88l7.47,6.16c-15.34,18.58-50.83,69.56-49.15,141.34,1.51,64.3,31.43,109.84,49.13,131.27l-7.47,6.17Z" />
   <path class="icon-color" d="M148.1,286.93l-7.47-6.17c17.7-21.43,47.63-66.98,49.13-131.27,1.68-71.78-33.81-122.75-49.15-141.34l7.47-6.16c16.03,19.42,53.12,72.69,51.36,147.73-1.58,67.21-32.85,114.82-51.35,137.21Z" />
   <rect class="icon-color" x="18.53" y="82.92" width="250.48" height="9.68" />
   <rect class="icon-color" x="7.15" y="139.45" width="276.59" height="9.68" />
   <rect class="icon-color" x="13.7" y="192.97" width="257.58" height="9.68" />
</svg>`
const INFOGRAPHIC_SVG = `<svg  xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 281.18 292.55">
   <rect class="icon-color" x="25.92" y="44.19" width="81.96" height="11.83" rx="5" ry="5" />
   <rect class="icon-color" x="25.92" y="75.64" width="81.96" height="11.83" rx="5" ry="5" />
   <rect class="icon-color" x="25.92" y="105.93" width="81.96" height="11.83" rx="5" ry="5" />
   <rect class="icon-color" x="162.67" y="174.99" width="81.96" height="11.83" rx="5" ry="5" />
   <rect class="icon-color" x="162.67" y="206.44" width="81.96" height="11.83" rx="5" ry="5" />
   <rect class="icon-color" x="162.67" y="236.73" width="81.96" height="11.83" rx="5" ry="5" />
   <path class="icon-color" d="M197.18,138.63c-31.47,0-57.07-25.6-57.07-57.07s25.6-57.07,57.07-57.07,57.07,25.6,57.07,57.07-25.6,57.07-57.07,57.07Zm0-102.31c-24.94,0-45.24,20.29-45.24,45.24s20.29,45.24,45.24,45.24,45.24-20.29,45.24-45.24-20.29-45.24-45.24-45.24Z" />
   <polygon class="icon-color" points="124.31 266.77 29.47 266.77 29.47 160.34 36.57 160.34 36.57 259.67 124.31 259.67 124.31 266.77" />
   <polygon class="icon-color" points="124.31 270.36 124.31 255.31 133.56 262.52 124.31 270.36" />
   <polygon class="icon-color" points="40.54 161.21 25.5 161.21 32.71 151.95 40.54 161.21" />
   <polygon class="icon-color" points="45.03 250.45 45.03 172.06 76.66 233.56 92.25 210.66 112.29 251.49 45.03 250.45" />
   <path class="icon-color" d="M257.63,292.55H23.56c-12.99,0-23.56-10.57-23.56-23.56V23.56C0,10.57,10.57,0,23.56,0h234.07c12.99,0,23.56,10.57,23.56,23.56v245.44c0,12.99-10.57,23.56-23.56,23.56ZM23.56,8.28c-8.42,0-15.27,6.85-15.27,15.27v245.44c0,8.42,6.85,15.28,15.27,15.28h234.07c8.42,0,15.27-6.85,15.27-15.28V23.56c0-8.42-6.85-15.27-15.27-15.27H23.56Z" />
</svg>`
const EBOOK_SVG = `<svg  xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 165.01 290.71">
   <path class="icon-color" d="M136.04,290.71H28.96c-15.97,0-28.96-12.99-28.96-28.96V28.96C0,12.99,12.99,0,28.96,0h107.08c15.97,0,28.96,12.99,28.96,28.96v232.78c0,15.97-12.99,28.96-28.96,28.96ZM28.96,10.26c-10.31,0-18.7,8.39-18.7,18.7v232.78c0,10.31,8.39,18.7,18.7,18.7h107.08c10.31,0,18.7-8.39,18.7-18.7V28.96c0-10.31-8.39-18.7-18.7-18.7H28.96Z" />
   <rect class="icon-color" x="10.02" y="223.71" width="145.47" height="10.14" />
   <circle class="icon-color" cx="82.5" cy="257.62" r="9.14" />
   <rect class="icon-color" x="26.69" y="35.55" width="104.45" height="10.26" rx="5" ry="5" />
   <rect class="icon-color" x="26.69" y="65.3" width="47.7" height="10.26" rx="5" ry="5" />
   <rect class="icon-color" x="26.69" y="95.06" width="104.45" height="10.26" rx="5" ry="5" />
   <rect class="icon-color" x="26.69" y="124.81" width="52.22" height="10.26" rx="5" ry="5" />
   <rect class="icon-color" x="26.69" y="184.32" width="52.22" height="10.26" rx="5" ry="5" />
   <rect class="icon-color" x="26.69" y="154.57" width="104.45" height="10.26" rx="5" ry="5" />
</svg>`
const BLOGPOST_SVG = `<svg  xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 294.34 255.92">
   <path class="icon-color" d="M255.6,246.12H38.63c-10.62,0-19.25-8.64-19.25-19.25V93.55c0-10.61,8.64-19.25,19.25-19.25h34.89v8.55H38.63c-5.9,0-10.71,4.8-10.71,10.71V226.87c0,5.9,4.8,10.71,10.71,10.71H255.6c5.9,0,10.71-4.8,10.71-10.71V93.55c0-5.9-4.8-10.71-10.71-10.71h-34.49v-8.55h34.49c10.62,0,19.25,8.64,19.25,19.25V226.87c0,10.62-8.64,19.25-19.25,19.25Z" />
   <path class="icon-color" d="M.33,236.21H293.9c.29,1.24,1.51,7.11-2.01,12.87-2.68,4.38-6.17,6.84-8.04,6.84H12.18c-1.7,0-8.26-.79-11.04-9.25-1.57-4.78-1.16-8.82-.8-10.46Z" />
   <rect class="icon-color" x="92.96" y="30.82" width="84.45" height="9.82" rx="4.91" ry="4.91" />
   <rect class="icon-color" x="92.96" y="60.54" width="111.47" height="9.82" rx="4.91" ry="4.91" />
   <rect class="icon-color" x="92.96" y="90.25" width="24.95" height="9.82" rx="4.91" ry="4.91" />
   <rect class="icon-color" x="91.89" y="119.96" width="34.9" height="9.82" rx="4.91" ry="4.91" />
   <rect class="icon-color" x="91.89" y="149.67" width="41.27" height="9.82" rx="4.91" ry="4.91" />
   <rect class="icon-color" x="92.96" y="179.39" width="99.56" height="9.82" rx="4.91" ry="4.91" />
   <rect class="icon-color" x="92.96" y="209.1" width="84.45" height="9.82" rx="4.91" ry="4.91" />
   <path class="icon-color" d="M148.55,142.22l11.52-2.66-8.86-8.86-2.66,11.52Z" />
   <path class="icon-color" d="M152.85,126.81l30.49-30.49,11.09,11.09-30.49,30.49-11.09-11.09Z" />
   <path class="icon-color" d="M199.52,99.09c0-4.33-3.51-7.84-7.84-7.84-2.08,0-4.07,.83-5.54,2.3l11.09,11.09c1.48-1.47,2.3-3.46,2.3-5.55h0Z" />
   <path class="icon-color" d="M208.94,244.45H86.55c-8.27,0-15-6.73-15-15V15c0-8.27,6.73-15,15-15h122.39c8.27,0,15,6.73,15,15V229.46c0,8.27-6.73,15-15,15ZM86.55,6c-4.96,0-9,4.04-9,9V229.46c0,4.96,4.04,9,9,9h122.39c4.96,0,9-4.04,9-9V15c0-4.96-4.04-9-9-9H86.55Z" />
</svg>`
const WEBINAR_SVG = `<svg  xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 259.58 291.82">
   <path class="icon-color" d="M255.07,258.81H4.51c-2.49,0-4.51-2.02-4.51-4.51V64.77c0-2.49,2.02-4.51,4.51-4.51h62.54v9.01H9.01V249.8H250.56V69.27h-59.62v-9.01h64.13c2.49,0,4.51,2.02,4.51,4.51V254.31c0,2.49-2.02,4.51-4.51,4.51Z" />
   <rect class="icon-color" x="6.89" y="213.67" width="248.18" height="9.01" />
   <rect class="icon-color" x="99.8" y="254.31" width="9.01" height="31.88" />
   <rect class="icon-color" x="150.62" y="254.31" width="9.01" height="31.88" />
   <path class="icon-color" d="M192.14,291.82H67.75c-2.49-.02-4.49-2.06-4.47-4.55,.02-2.47,2.04-4.47,4.51-4.47h124.39c2.49,.02,4.49,2.06,4.47,4.55-.02,2.47-2.04,4.47-4.51,4.47Z" />
   <path class="icon-color" d="M129.97,123.15c-5.3,0-16.6-3.36-26.01-12.61-7.98-7.85-11.24-16.8-12.57-22.92h-2.28c-.8,0-1.57-.24-2.23-.69-6.3-4.31-9.62-11.49-8.68-18.74,.81-6.21,4.49-10.28,6.96-12.36V26.32c0-.31,.04-.62,.11-.92,.98-4.07,3.23-10.05,8.42-15.43C100.51,2.89,108.64,.74,112.87,.09c.2-.03,.4-.05,.6-.05h46.69c2.18,0,3.94,1.77,3.94,3.94s-1.77,3.94-3.94,3.94h-46.38c-3.32,.55-9.35,2.26-14.41,7.5-3.78,3.92-5.52,8.27-6.33,11.37v30.98c0,1.33-.67,2.57-1.78,3.29-.77,.5-4.62,3.26-5.25,8.12-.5,3.87,1.2,7.89,4.39,10.53h4.27c1.97,0,3.64,1.46,3.91,3.41,.65,4.77,2.92,13.94,10.88,21.77,7.95,7.82,17.23,10.35,20.48,10.35,3.67,0,11.83-1.68,19.15-7.98,10.29-8.86,11.54-21.2,11.68-24.77,.04-1.08,.52-2.1,1.34-2.81,.81-.71,1.88-1.06,2.96-.96l4.11,.37c3.56-2.87,5.36-7.27,4.51-11.29-.89-4.19-4.35-6.52-5.39-7.14-1.2-.71-1.94-2-1.94-3.4V25.31c-.04-2.43-.59-3.66-1.4-4.75-.48-.6-2.75-2.46-3.84-3.36-1.3-1.07-2.06-1.7-2.57-2.2-1.53-1.55-1.53-4.04,.02-5.58s4.04-1.53,5.58,.02c.24,.25,1.24,1.06,1.97,1.66,2.52,2.06,4.24,3.51,5.15,4.72,1.98,2.63,2.93,5.63,2.99,9.43v29.95c2.54,1.94,6,5.48,7.16,10.99,1.6,7.55-1.99,15.7-8.93,20.28-.75,.49-1.64,.72-2.53,.64l-1.68-.15c-.95,6.52-4.03,17.71-14.02,26.31-8.48,7.3-18.38,9.89-24.29,9.89Z" />
   <path class="icon-color" d="M105.76,44.01c-1.8,0-3.43-1.24-3.84-3.07-.32-1.41-.81-5.08,1.46-8.7,2.2-3.5,5.92-5.05,8.67-5.32,.13-.01,.26-.02,.39-.02h29.2c.98-.04,7.68-.5,11.81-6.3,3.89-5.47,3.17-14.2,2.87-15.69-.56-2.11,.71-4.22,2.81-4.77,2.11-.56,4.28,.74,4.83,2.85,.49,1.86,1.84,13.86-4.09,22.19-6.29,8.83-16.14,9.55-18.05,9.61-.04,0-.08,0-.12,0h-29.02c-.45,.07-1.85,.4-2.64,1.66-.75,1.19-.5,2.51-.45,2.76,.48,2.12-.85,4.24-2.98,4.72-.29,.07-.59,.1-.87,.1Z" />
   <path class="icon-color" d="M37.65,204.6c-2.18,0-3.94-1.77-3.94-3.94v-24.25c0-.38,.06-.76,.17-1.13,1.91-6.39,5.02-12.25,9.22-17.42,4.08-5.02,9.02-9.15,14.68-12.29,.16-.09,.33-.17,.51-.24l34.64-13.22c3.78-1.53,7.34-3.94,10.22-6.92,2.96-3.07,5.26-6.82,6.67-10.85,.72-2.05,2.97-3.14,5.03-2.42,2.06,.72,3.14,2.97,2.42,5.03-1.78,5.09-4.7,9.83-8.44,13.71-3.64,3.78-8.14,6.82-13.01,8.79l-34.44,13.15c-4.68,2.64-8.77,6.08-12.15,10.24-3.43,4.22-5.99,8.98-7.62,14.16v23.66c0,2.18-1.77,3.94-3.94,3.94Z" />
   <path class="icon-color" d="M114.66,167.95c-.07,0-.14,0-.21,0-1.15-.06-2.22-.63-2.92-1.54l-19.39-25.37c-1.32-1.73-.99-4.21,.74-5.53,1.73-1.32,4.2-.99,5.53,.74l16.58,21.7,31.22-32.9c1.5-1.58,4-1.64,5.58-.15,1.58,1.5,1.65,4,.15,5.58l-34.4,36.26c-.75,.79-1.78,1.23-2.86,1.23Z" />
   <path class="icon-color" d="M144.39,167.95c-1.09,0-2.14-.45-2.89-1.26l-35.27-37.97c-1.48-1.6-1.39-4.09,.21-5.57,1.6-1.48,4.09-1.39,5.57,.21l32.14,34.6,15.48-19.47c-3-.82-6.4-2.32-9.6-5.03-7.32-6.17-8.69-14.3-8.95-17.5-.17-2.17,1.45-4.07,3.62-4.24,2.16-.18,4.07,1.45,4.24,3.62,.14,1.73,.98,7.72,6.17,12.09,4.59,3.88,9.79,4.16,11.83,4.11,1.54-.04,2.95,.81,3.64,2.18s.51,3.02-.44,4.22l-22.67,28.52c-.72,.9-1.79,1.44-2.94,1.49-.05,0-.1,0-.15,0Z" />
   <path class="icon-color" d="M129.93,205.81c-2.18,0-3.94-1.77-3.94-3.94v-49.8c0-2.18,1.77-3.94,3.94-3.94s3.94,1.77,3.94,3.94v49.8c0,2.18-1.77,3.94-3.94,3.94Z" />
   <path class="icon-color" d="M59.93,204.6c-2.18,0-3.94-1.77-3.94-3.94v-18.72c0-2.18,1.77-3.94,3.94-3.94s3.94,1.77,3.94,3.94v18.72c0,2.18-1.77,3.94-3.94,3.94Z" />
   <path class="icon-color" d="M201.78,204.6c-17.28,0-31.34-14.06-31.34-31.34s14.06-31.34,31.34-31.34,31.34,14.06,31.34,31.34-14.06,31.34-31.34,31.34Zm0-54.79c-12.93,0-23.45,10.52-23.45,23.45s10.52,23.45,23.45,23.45,23.45-10.52,23.45-23.45-10.52-23.45-23.45-23.45Z" />
   <path class="icon-color" d="M195.38,165.98v13.51c0,1.31,1.4,2.14,2.55,1.52l12.47-6.76c1.2-.65,1.2-2.38,0-3.03l-12.47-6.76c-1.15-.62-2.55,.21-2.55,1.52Z" />
</svg>`
const VIDEO_SVG = `<svg  xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 288.29 181.66">
   <path class="icon-color" d="M285.29,16.2c-1.86-1.1-4.15-1.14-6.05-.11l-66.14,36.12V30.54c-.02-16.86-13.68-30.52-30.54-30.54H30.54C13.68,.02,.02,13.68,0,30.54v120.58c.02,16.86,13.68,30.52,30.54,30.54H182.56c16.86-.02,30.52-13.68,30.54-30.54v-21.28l66.15,36.12c1.89,1.03,4.19,.99,6.04-.11,1.86-1.1,2.99-3.1,2.99-5.25V21.45c0-2.16-1.14-4.15-2.99-5.25Zm-84.41,134.92c0,10.12-8.21,18.31-18.32,18.32H30.54c-10.12-.01-18.31-8.21-18.32-18.32V30.54c.01-10.11,8.21-18.31,18.32-18.32H182.56c10.11,.01,18.31,8.21,18.32,18.32v120.58Zm75.18-.82l-62.97-34.38v-49.8l62.97-34.38v118.56Z" />
</svg>`
const PAPER_SVG = `<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 261.28 285.03">
   <path class="icon-color" d="M231.59,285.03H89.07c-16.37,0-29.69-13.31-29.69-29.69V77.2c0-16.38,13.33-29.69,29.69-29.69H231.59c16.37,0,29.69,13.31,29.69,29.69V255.34c0,16.38-13.33,29.69-29.69,29.69ZM89.07,59.38c-9.82,0-17.81,7.99-17.81,17.81V255.34c0,9.82,7.99,17.81,17.81,17.81H231.59c9.82,0,17.81-7.99,17.81-17.81V77.2c0-9.82-7.99-17.81-17.81-17.81H89.07Z" />
   <path class="icon-color" d="M41.57,249.4h-11.88c-16.37,0-29.69-13.31-29.69-29.69V29.69C0,13.31,13.33,0,29.69,0H172.2c16.37,0,29.69,13.31,29.69,29.69,0,3.28-2.66,5.94-5.94,5.94s-5.94-2.66-5.94-5.94c0-9.82-7.99-17.81-17.81-17.81H29.69c-9.82,0-17.81,7.99-17.81,17.81V219.71c0,9.82,7.99,17.81,17.81,17.81h11.88c3.28,0,5.94,2.66,5.94,5.94s-2.66,5.94-5.94,5.94Z" />
   <path class="icon-color" d="M207.83,196.38H112.82c-3.28,0-5.94-2.66-5.94-5.94s2.66-5.94,5.94-5.94h95.01c3.28,0,5.94,2.66,5.94,5.94s-2.66,5.94-5.94,5.94Z" />
   <path class="icon-color" d="M207.83,241.12H112.82c-3.28,0-5.94-2.66-5.94-5.94s2.66-5.94,5.94-5.94h95.01c3.28,0,5.94,2.66,5.94,5.94s-2.66,5.94-5.94,5.94Z" />
   <path class="icon-color" d="M207.83,151.63H112.82c-3.28,0-5.94-2.66-5.94-5.94s2.66-5.94,5.94-5.94h95.01c3.28,0,5.94,2.66,5.94,5.94s-2.66,5.94-5.94,5.94Z" />
   <path class="icon-color" d="M207.83,106.89H112.82c-3.28,0-5.94-2.66-5.94-5.94s2.66-5.94,5.94-5.94h95.01c3.28,0,5.94,2.66,5.94,5.94s-2.66,5.94-5.94,5.94Z" />
</svg>`
const SLIDEDECK_SVG = `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.7 263.41">
  <path class="icon-color" d="M256.19,206.61H41.18c-11.56,0-20.96-9.4-20.96-20.96V38.08c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5V185.65c0,7.7,6.26,13.96,13.96,13.96h215.01c7.7,0,13.96-6.26,13.96-13.96V38.08c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5V185.65c0,11.56-9.4,20.96-20.96,20.96Z"/>
  <path class="icon-color" d="M279.25,37.58H13.45c-7.41,0-13.45-6.03-13.45-13.45V13.45C0,6.03,6.03,0,13.45,0H279.25c7.41,0,13.45,6.03,13.45,13.45v10.69c0,7.41-6.03,13.45-13.45,13.45ZM13.45,7c-3.55,0-6.45,2.89-6.45,6.45v10.69c0,3.55,2.89,6.45,6.45,6.45H279.25c3.55,0,6.45-2.89,6.45-6.45V13.45c0-3.55-2.89-6.45-6.45-6.45H13.45Z"/>
  <path class="icon-color" d="M146.75,228.46c-1.93,0-3.5-1.57-3.5-3.5v-21.85c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5v21.85c0,1.93-1.57,3.5-3.5,3.5Z"/>
  <path class="icon-color" d="M146.35,263.41c-10.85,0-19.68-8.83-19.68-19.67s8.83-19.67,19.68-19.67,19.67,8.83,19.67,19.67-8.83,19.67-19.67,19.67Zm0-32.35c-6.99,0-12.68,5.69-12.68,12.67s5.69,12.67,12.68,12.67,12.67-5.69,12.67-12.67-5.69-12.67-12.67-12.67Z"/>
  <rect class="icon-color" x="152.08" y="78.84" width="93.91" height="9.48" rx="4.74" ry="4.74"/>
  <rect class="icon-color" x="152.08" y="111.27" width="93.91" height="9.48" rx="4.74" ry="4.74"/>
  <rect class="icon-color" x="152.08" y="143.71" width="93.91" height="9.48" rx="4.74" ry="4.74"/>
  <g>
    <path class="icon-color" d="M82.41,155.41c-.06,0-.13,0-.19,0-3.94-.19-13.95-1.57-21.88-10.33-7.84-8.66-8.3-18.65-8.13-22.54,.1-4.08,1.31-14.68,10.16-23.34,8.26-8.08,18.15-9.41,22.02-9.61,1.09-.07,2.16,.34,2.96,1.1,.79,.76,1.24,1.8,1.24,2.9v25.13h23.52c1.09,0,2.14,.45,2.9,1.24,.76,.79,1.15,1.86,1.1,2.95-.22,4.51-1.61,13.25-8.47,21.02-8.5,9.63-20.33,11.25-25.02,11.49-.07,0-.14,0-.21,0Zm-1.82-57.26c-3.59,.79-8.39,2.61-12.63,6.75-6.95,6.8-7.7,15.42-7.76,17.9-.1,2.34,.05,10.25,6.06,16.9,5.92,6.54,13.61,7.55,16.14,7.7,3.11-.19,12.72-1.39,19.24-8.78,3.56-4.04,5.2-8.43,5.93-11.92h-22.98c-2.21,0-4-1.79-4-4v-24.55Z"/>
    <path class="icon-color" d="M128.17,110.16h-26.39c-2.21,0-4-1.79-4-4v-27.27c0-1.17,.51-2.28,1.4-3.04,.89-.76,2.06-1.1,3.21-.92,4.72,.73,13.73,3.17,21.05,11.38,6.42,7.19,8.21,15.22,8.7,19.37,.14,1.14-.22,2.27-.98,3.13-.76,.85-1.85,1.34-2.99,1.34Zm-22.39-8h17.5c-.92-3.14-2.63-6.96-5.8-10.52-3.78-4.23-8.22-6.47-11.69-7.65v18.17Z"/>
  </g>
</svg>`

const ARTICLE_SVG = `<svg  viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 29.5H10V38.5C10 41 11.4925 41.4835 14 41.5" class="icon-color" stroke-width="1.2"/>
<path d="M15.5 11V38.5C15.5 40 15 41.5 13.5 41.5H37C39 41.5 40.0257 40.414 40 38.5V11C39.9832 9.56829 39.5945 9.07918 38 9H17.5C15.9761 9.04395 15.5465 9.45565 15.5 11Z" class="icon-color" stroke-width="1.2"/>
<rect x="18.5" y="16.5" width="18" height="1.2" rx="0.6" class="icon-color"/>
<rect x="18.5" y="20.5" width="18" height="1.2" rx="0.6" class="icon-color"/>
<rect x="18.5" y="24.5" width="18" height="1.2" rx="0.6" class="icon-color"/>
<rect x="18.5" y="28.5" width="12" height="1.2" rx="0.6" class="icon-color"/>
</svg>`

<template>
  <section class="holder" v-if="showComponent">
    <h2 v-html="translateLabels('Speakers')"></h2>
    <div class="speaker-holder">
      <template v-for="s in speakers">
        <!-- eslint-disable-next-line vue/require-v-for-key -->
        <article class="speaker-tile">
          <div class="image-clmn">
            <div class="img" :style="getImageStyle(s.image_url)"></div>
            <div class="social-row" v-if="hasAny(s.social_urls)">
              <a v-if="s.social_urls.twitter" :href="s.social_urls.twitter" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 22 22"
                  height="24"
                  width="25"
                  fill="none"
                  class="ss-icon"
                >
                  <circle class="icon-color" cx="11" cy="11" r="10" />
                  <path stroke-width="0.7px" class="icon-contrast-color" d="M9.03,7.34h-2.28l5.93,7.45h2.28l-5.93-7.45Z" />
                  <path
                    class="icon-contrast-color"
                    stroke-width="0"
                    d="M14.96,6.9h-1.65l-6.97,8.2h1.65s6.97-8.2,6.97-8.2Z"
                  />
                </svg>
              </a>
              <a v-if="s.social_urls.linkedin" :href="s.social_urls.linkedin" target="_blank">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12.56" cy="12" r="11" class="icon-color" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.4096 6.00008C7.6573 5.99208 7.07217 6.55218 7.06023 7.28431C7.04829 8.03644 7.60953 8.62854 8.34193 8.64055C9.07433 8.65655 9.6714 8.07245 9.67538 7.33632C9.67936 6.61219 9.10617 6.00409 8.4096 6.00008ZM13.2074 17.0022H10.9624V9.68896H13.1118V10.6491C13.184 10.5712 13.2486 10.4996 13.3087 10.4329L13.3087 10.4329L13.3087 10.4329C13.4275 10.3011 13.5289 10.1886 13.6372 10.085C14.5687 9.20087 16.2086 9.41291 16.9092 9.85699C17.5739 10.2771 17.8565 10.9372 17.9481 11.6733C18.0826 12.7576 18.0683 13.845 18.0539 14.9381C18.0449 15.6237 18.0359 16.3115 18.0635 17.0022H15.8344C15.7665 16.9247 15.7728 16.8327 15.7791 16.7412C15.7809 16.7147 15.7827 16.6882 15.7827 16.6622C15.7787 15.574 15.7787 14.4818 15.7787 13.3936C15.7787 13.1056 15.7708 12.8175 15.719 12.5295C15.5996 11.8533 15.2732 11.5213 14.6881 11.4893C13.9557 11.4533 13.5099 11.7453 13.3228 12.4014C13.2074 12.7975 13.2074 13.2056 13.2074 13.6136V17.0022ZM7.25731 9.677H9.47441V17.0063H7.25731V9.677Z"
                    class="icon-contrast-color"
                  />
                </svg>
              </a>
              <a v-if="s.social_urls.web" :href="s.social_urls.web" target="_blank">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12.5601" cy="12" r="11" class="icon-color" />
                  <path
                    d="M11.3764 13.2996C12.5466 14.5533 14.4437 14.5533 15.6138 13.2996L17.3118 11.4803C18.4819 10.2266 18.4819 8.19397 17.3118 6.94027C16.1417 5.68658 14.2445 5.68657 13.0744 6.94027L12.2254 7.84989C11.9931 8.09885 11.9931 8.5025 12.2254 8.75145C12.4578 9.00041 12.8345 9.00041 13.0669 8.75146L13.9159 7.84183C14.6212 7.08605 15.7649 7.08606 16.4703 7.84184C17.1757 8.59762 17.1757 9.82298 16.4703 10.5788L14.7724 12.398C14.067 13.1538 12.9233 13.1538 12.2179 12.398C11.9855 12.149 11.6088 12.149 11.3764 12.398C11.1441 12.647 11.1441 13.0506 11.3764 13.2996Z"
                    class="icon-contrast-color"
                  />
                  <path
                    d="M13.8129 10.7997C12.6428 9.54596 10.7457 9.54596 9.57555 10.7997L7.87759 12.6189C6.70747 13.8726 6.70747 15.9053 7.87759 17.159C9.04771 18.4126 10.9448 18.4127 12.115 17.159L12.9639 16.2493C13.1963 16.0004 13.1963 15.5967 12.9639 15.3478C12.7316 15.0988 12.3548 15.0988 12.1225 15.3478L11.2735 16.2574C10.5681 17.0132 9.42444 17.0132 8.71905 16.2574C8.01365 15.5016 8.01365 14.2762 8.71905 13.5205L10.417 11.7012C11.1224 10.9454 12.2661 10.9454 12.9715 11.7012C13.2038 11.9502 13.5806 11.9502 13.8129 11.7012C14.0453 11.4523 14.0453 11.0486 13.8129 10.7997Z"
                    class="icon-contrast-color"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div :class="`title-clmn ${s.description ? 'border' : ''}`">
            <h3 v-html="translateSpeaker(s).name"></h3>
            <span v-html="translateSpeaker(s).title"></span>
          </div>
        </article>

        <!-- eslint-disable-next-line vue/require-v-for-key -->
        <div class="desc-clmn" v-if="hasDescription">
          <p v-html="translateSpeaker(s).description"></p>
        </div>
      </template>
    </div>
  </section>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/runtime-core"
import injectStrict from "@/utils/injectStrict"
import { translationKey } from "@/symbols"
import { SpeakerInfo, SocialUrls } from "@/models/SpeakerInfo"
export default defineComponent({
  props: {
    speakers: {
      type: Object as PropType<Array<SpeakerInfo>>,
      required: false,
      default: null
    }
  },
  methods: {
    getImageStyle(url: string | null | undefined) {
      return `background-image: url("${url ?? "https://js.static.parmonic.ai/static/default-speaker-image.png"}") !important`
    },
    hasAny(urls: SocialUrls): boolean {
      if (urls) {
        return urls.twitter !== null || urls.linkedin !== null || urls.web !== null
      }
      return false
    },
    infoHasDescription(info: SpeakerInfo): boolean {
      return false
    }
  },
  setup(props) {
    const showComponent = computed(() => props.speakers && props.speakers.length > 0)
    const hasDescription = computed(() => {
      var has = false
      if (!props.speakers) return false
      props.speakers.forEach(element => {
        if (element.description) has = true
      })
      return has
    })
    const { translateSpeaker, translateLabels } = injectStrict(translationKey)
    return {
      showComponent,
      hasDescription,
      translateSpeaker,
      translateLabels
    }
  }
})
</script>

<style lang="scss" scoped>
.icon-color {
  fill: var(--theme-color);
}

.icon-contrast-color {
  fill: var(--theme-contrast);
}

.holder {
  margin-top: 3em;

  h2 {
    box-sizing: border-box;
    font-family: var(--font-family);
    font-weight: 500;
    line-height: normal;
    color: var(--font-color);
    font-size: 1.625em;
    @media screen and (max-width: 756px) {
      padding-bottom: 0px;
    }
  }

  .speaker-holder {
    width: 100%;
    display: grid;
    grid-template-columns: calc(33.33% + 8px) calc(33.33% + 8px) calc(33.33% + 8px);
    margin: -12px;

    @media screen and (max-width: 756px) {
      display: unset;
      grid-template-columns: unset;
      margin-bottom: unset;
    }

    &::before {
      display: none;
    }

    .speaker-tile {
      display: flex;
      margin-bottom: 2em;
      padding: 12px;
      align-items: center;
      &:nth-child(1) {
        padding-top: 0;
      }
      @media screen and (max-width: 756px) {
        padding: unset;
        margin-bottom: 0.5em;
        padding-top: 1em;
      }

      .image-clmn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .img {
          width: 100px;
          height: 100px;
          border: 2px solid var(--theme-color-7);
          border-radius: 100%;
          box-sizing: border-box;
          background-size: cover !important;
          background-position: center;
        }

        .social-row {
          margin-top: 0.375em;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 0.25em;
          @media screen and (max-width: 756px) {
            margin-top: 0.5em;
          }
        }
      }

      .title-clmn.border {
        border-right: 4px solid rgba(211, 211, 211, 0.4);
        @media screen and (max-width: 756px) {
          border-right: unset;
        }
      }

      .title-clmn {
        padding: 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: fit-content;
        flex-grow: 1;
        max-width: calc(100% - 128px);
        line-height: initial;

        /* A hack... TODO: try to not set a line height everywhere, so that it larger font sizes do not look squished */
        h3 {
          font-family: var(--font-family);
          color: var(--font-color);
          font-size: 1.25em;
          line-height: normal;
          padding: 0.25em;
          padding-left: 0px;
        }

        span {
          font-family: var(--font-family);
          color: var(--theme-color-7);
          font-size: 1.125em;
          line-height: normal;
          font-weight: bold;
        }
      }
    }

    .desc-clmn {
      grid-column: 2 / 4;
      margin-left: -1rem;
      /* to make up for the 1rem padding set on the text  */
      line-height: initial;
      /* A hack... TODO: try to not set a line height everywhere, so that it larger font sizes do not look squished */
      display: flex;
      justify-content: left;
      align-items: center;

      p {
        padding-left: 2em;
        font-family: var(--font-family);
        color: var(--font-color);
        font-size: 1.125em;
        line-height: normal;
        margin: 0px;
        @media screen and (max-width: 756px) {
          padding-left: 1em;
        }
      }
    }
  }
}
</style>
